import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // useLocationを追加
import { login } from '../../api/auth';
import { useAuth } from '../../contexts/AuthContext'; // AuthContextからuseAuthをimport
import GoogleAuthButton from '../../components/GoogleAuthButton';
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAdminSectionOpen, setIsAdminSectionOpen] = useState(false); // 追加
  const navigate = useNavigate();
  const location = useLocation(); // 追加
  const { login: loginContext } = useAuth(); // useAuthからlogin関数を取得

  // エラーメッセージがある場合は表示
  React.useEffect(() => {
    // URLからexpiredパラメータを確認
    const params = new URLSearchParams(window.location.search);
    if (params.get('message') === 'expired') {
      setError('ログインしてください(セッションなし)');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login({ email, password });
      console.log('Login response:', response);
      const { token, role, username } = response;
      // 明示的にusernameをログに出力
      console.log('Username from login:', username);
      // AuthContextのlogin関数を呼び出してログイン状態を更新
      loginContext(token, role, username);
      // リダイレクト元のページが指定されている場合はそこに戻る
      navigate(location.state?.from || '/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setError('ログインに失敗しました');
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>ログイン</h2>

        {/* エラーメッセージの表示位置を最上部に移動 */}
        {error && (
          <div className="error-message-container">
            <p className="error-message">{error}</p>
          </div>
        )}

        <div className="google-login">
          <div className="login-description">
            <p className="login-warning">未ログインでは解答できません</p>
            <p>agest.co.jpでログイン可能</p>
            <p>Agestアカウント:全問題を開放</p>
            {/* <p>Googleアカウント:一部問題のみ開放</p> */}
          </div>
          <GoogleAuthButton />
        </div>

        <div className="divider">
          <span
            className="admin-toggle"
            onClick={() => setIsAdminSectionOpen(!isAdminSectionOpen)}
          >
            ▼管理用ログイン
          </span>
        </div>

        {isAdminSectionOpen && (
          <div className="admin-login-section">
            {/* {error && <p className="error-message">{error}</p>} */}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">メールアドレス:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">パスワード:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit">ログイン</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
